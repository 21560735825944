const axios = require("axios").default;

//BASE URL For backend
import { baseURL } from "@/assets/js/urls";
const baseUrl = baseURL.API_URL;

const getApiService = {
  get: (endpoint, token) => {
    let customAxios = axios.create({
        headers: {
        Authorization: `token ${token}`,
        },
    });
    return customAxios.get(`${baseUrl}${endpoint}`);
  },
};

export default getApiService;