<template>
<div>
     <footer class="pt-1 border-top fixed-bottom text-center">
    <nav aria-label="..." class="d-flex" v-if="$route.path != '/surveysList'">
    <ul class="pagination pagination-lg fixed-bottom mb-5">
      <li class="page-item pointer" @click="goPreviousPage()">
        <a class="page-link" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span> <span class="ms-2">Previous</span>
        </a>
      </li>
      <li
      v-if="$route.path == '/emotionalAnalysis'"
      class="page-item" :class="submitLoader ? 'disabled' : 'pointer'"
      style="margin-left:auto" @click="!submitLoader ? submitSurvey() : null">

        <a class="page-link" aria-label="Next">
          <span class="me-2">Submit</span> <span aria-hidden="true"></span>
            <div class="spinner-border ml-auto mt-2"
              v-if="submitLoader"
              style="width: 1.4rem; height: 1.4rem;" role="status">
              <span class="sr-only">Loading...</span>
              </div>
        </a>
      </li>
            <li
      v-else
      class="page-item pointer" style="margin-left:auto" @click="goNextPage()">

        <a class="page-link" aria-label="Next">
          <span class="me-2">Next</span> <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>

    BRANDthrō, Inc. &middot; &copy; 2023, All Rights Reserved

  </footer>
    </div>
</template>
<script>
export default {
data(){
  return{
    submitLoader: false
  }
},
methods:{
goPreviousPage(){
let currentRoute = this.$route.path;
currentRoute == "/audienceDefinition"
? this.resetSelectedSurvey()
: currentRoute == "/screenerQuestions"
? this.$router.push("/audienceDefinition")
: currentRoute == "/featureAnalyses"
? this.$router.push("/screenerQuestions")
: currentRoute == "/emotionalAnalysis"
? this.$router.push("/featureAnalyses")
: ()=>{}
},
goNextPage(){
let currentRoute = this.$route.path;
currentRoute == "/audienceDefinition" || "/newAudienceDefinition"
? this.$router.push("/screenerQuestions")
:currentRoute == "/screenerQuestions"
? this.$router.push("/featureAnalyses")
: currentRoute == "/featureAnalyses"
? this.$router.push("/emotionalAnalysis")
: ()=>{}
},
submitSurvey(){
this.submitLoader=true;
this.$store.dispatch("submitSelectedSurvey").then(()=>{
  this.submitLoader=false;
  this.$swal({
  icon: 'success',
  title: 'Survey Updated Successfully',
  showConfirmButton: false,
  timer: 1500
  })
})
.catch(() => {
  this.submitLoader=false;
  this.$swal({
  icon: 'error',
  text: 'Error In Updating Survey, Try Again Later!',
  showConfirmButton: false,
  timer: 1200
  })
});
},
resetSelectedSurvey(){
  this.$store.commit('resetSurveyData');
  this.$router.push("/surveysList");
}

}
}
</script>
<style scoped>

.pagination{
padding: 1rem 1rem 0;
/* background:white; */
}
@media (min-width: 360px) and (max-width: 1024px){
  .pagination{
background:white;
}
}

</style>