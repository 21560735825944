<template>
<div>
    <Navbar/>
    <div class="row d-flex main-layout-wrapper gx-0 mx-auto p-3 py-md-5">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xl-3">
    <SideBar/>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-12 col-xl-9">
    <router-view />
    </div>
    </div>
    <Footer/>
</div>
    
</template>
<script>
import Navbar from "@/components/SurveyNavbar.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
export default {
    components : {
        Navbar,
        Footer,
        SideBar,
    }
}
</script>
<style scoped>
@media (min-width: 375px) and (max-width:1024px){
    .main-layout-wrapper{
    height:calc(100vh - 229px);
    overflow-y: auto;
}
}
</style>