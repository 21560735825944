import Vue from "vue";
import { createApp } from 'vue'
import App from "./App.vue";
import router from "./router";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../src/assets/css/style.css"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import store from "./store";
import DefaultLayout from "./layouts/DefaultLayout";
import SurveyLayout from "./layouts/SurveyLayout";
import WelcomeUserLayout from "./layouts/WelcomeLayout";
Vue.component('survey-navbar-layout',SurveyLayout);
Vue.component('no-navbar-layout',DefaultLayout);
Vue.component('welcome-navbar-layout',WelcomeUserLayout);
Vue.config.productionTip = false;
export const eventBus = createApp(App)
eventBus.use(VueSweetalert2).use(router).use(store).mount("#app")

