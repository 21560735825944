<template>
<div class="sticky-sidebar sticky-sidebar-width">
    <!-- sidebar sub-navigation -->
    <nav id="bt-sidebar" class="sticky-top  py-3" style="z-index: 1">
      <ul class="nav flex-column">
        <li class="nav-item pointer"  @click="navigateToRoute('/audienceDefinition')">
          <a class="nav-link"
          :class="$route.path == '/audienceDefinition' ? '' : 'disabled'" title="Step 1. Audience Definition">Step 1. Audience Definition</a>
        </li>
        <li class="nav-item pointer" @click="navigateToRoute('/screenerQuestions')">
          <a class="nav-link" title="Step 2. Screener Questions"
          :class="$route.path == '/screenerQuestions' ? '' : 'disabled'">Step 2. Screener Questions</a>
          <div v-if="$route.path == '/screenerQuestions'">
            <span
             v-for="(question,index) in getScreenerQuestions"  :key="index"
             class="nav-text"
             style="display:block"
             >Q{{index+1}}. {{question.questionText ? question.questionText : 'Untitled Question'}}</span>
          </div>
        </li>
        <li class="nav-item pointer" aria-current="page" @click="navigateToRoute('/featureAnalyses')">
          <a class="nav-link" title="Step 3. Feature Analyses"
          :class="$route.path == '/featureAnalyses' ? '' : 'disabled'">Step 3. Feature Analyses</a>
        <div v-if="$route.path == '/featureAnalyses'" :key="analysisListKey">
          <span
          v-for="(analysis,index) in getFeatureAnaylses"  :key="index"
          class="nav-text mt-2"
          @click="hideOrShowFeatureSubNav(index)"
          style="display:block"
          >
            <i class="fas fa-play"
            :class="$store.state.featureSubNavArray[index] ? 'rotate-show-list-icon' : 'ml-1'"></i>
            {{index+1}}. {{analysis.featureType ? analysis.featureType : 'Untitled'}}
            <div v-show="$store.state.featureSubNavArray[index]"
            class="mt-2 text-truncate sub-nav-truncate-width" style="margin-left:29px;" v-for="(feature,i) in analysis.featuresList"  :key="i">
            {{feature.featureName ? feature.featureName : 'Untitled Feature'}}</div>
          </span>
        </div>
        </li>
        <li class="nav-item pointer"  @click="navigateToRoute('/emotionalAnalysis')">
          <a  class="nav-link" title="Step 4. Emotional Lingustic Analysis"
          :class="$route.path == '/emotionalAnalysis' ? '' : 'disabled'">Step 4. Emotional Lingustic Analysis</a>
          <div v-if="$route.path == '/emotionalAnalysis'">
            <span
             v-for="(category,index) in getCategories"  :key="index"
             class="nav-text mt-2"
             style="display:block"
          >
          {{index+1}}. {{category.categoryType ? category.categoryType : 'Untitled'}}
          </span>
        </div>
        </li>
      </ul>
    </nav>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data(){
  return{
  analysisListKey:0,
  }
  },
  computed: {
    ...mapGetters([
      'getScreenerQuestions',
      'getFeatureAnaylses',
      'getCategories',
      'getShowFeatureSubNavFlag'

    ]),
  },
  methods:{
  navigateToRoute(selectedRoute){
  this.$route.path == selectedRoute
  ? ()=>{}
  : this.$router.push(selectedRoute)
  },
  hideOrShowFeatureSubNav(index){
  this.$store.commit("updateShowFeatureSubNavArray",index);
  this.analysisListKey++;
  },
  },
  watch :{
  getShowFeatureSubNavFlag(){
  this.analysisListKey++;
  },

  }
}
</script>
<style scoped>
.nav-text{
font-weight:bold;
margin-left:60px;
white-space: nowrap;
width:160px;
overflow: hidden;
text-overflow: ellipsis !important;
}
.sub-nav-truncate-width{
  max-width : 160px;
}
  @media (min-width: 760px) and  (max-width: 1020px) {
  .nav-text {
    width: 100px;
  }
  .sub-nav-truncate-width{
  max-width : 100px;
  }
  }
.rotate-show-list-icon{
  transform: rotate(89deg);
  margin-left: 1px;
}
.sticky-sidebar {
    position: sticky;
    top: 127px;
}

.sticky-sidebar-width {
    max-width: 350px;
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: auto;
}

@media (min-width: 375px) and (max-width: 1024px) {
    .sticky-sidebar {
        position: sticky;
        height: 200px;
        top: -16px;
        background: white;
        z-index: 999;
        overflow-y: auto;
    }
    .sticky-sidebar-width {
        max-width: 100%;
        width: 100%;
        height: 200px;
        overflow-y: auto;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 0.1px;
    border-radius: 16px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: black;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: gray;
}

::-webkit-scrollbar-thumb:active {
    background: gray;
}

::-webkit-scrollbar-thumb:active {
    background: black;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: transparent;
}

::-webkit-scrollbar-track:active {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

</style>