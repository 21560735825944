<template>
<div>
    <Navbar/>
    <div class="row main-listing-view p-3 py-md-5">
    <router-view />
    </div>
    <Footer/>
</div>
    
</template>
<script>
import Navbar from "@/components/SurveyNavbar.vue";
import Footer from "@/components/Footer.vue";
export default {
    components : {
        Navbar,
        Footer,
    }
}
</script>
<style  scoped>
.main-listing-view{
    width: 100%;
}
</style>