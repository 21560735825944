import store from "../store/index";
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

export const router = createRouter({
history: createWebHashHistory(process.env.BASE_URL),

routes: [
  {
    path: "/",
    name: "loginView",
    meta: {layout : "no-navbar"},
    component: () =>
    import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {layout : "no-navbar"},
    component: () =>
    import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/surveysList",
    name: "surveysList",
    meta: {layout : "welcome-navbar",requireAuth: true},
    component: () =>
    import(/* webpackChunkName: "surveysList" */ "../views/SurveysListView.vue"),
  },
  {
    path: "/audienceDefinition",
    name: "1. Audience Definition",
    meta: {layout : "survey-navbar", requireAuth: true},
    component: () =>
      import(/* webpackChunkName: "audienceDefinition" */ "../views/AudienceDefinition.vue"),
  },
  {
    path: "/newAudienceDefinition",
    name: "1. New Audience Definition",
    meta: {layout : "survey-navbar", requireAuth: true},
    component: () =>
      import(/* webpackChunkName: "newAudienceDefinition" */ "../views/AudienceDefinition_New.vue"),
  },
  {
    path: "/screenerQuestions",
    name: "2. Screener Questions",
    meta: {layout : "survey-navbar", requireAuth: true},
    component: () =>
      import(/* webpackChunkName: "screenerQuestions" */ "../views/ScreenerQuestions.vue"),
  },
  {
    path: "/featureAnalyses",
    name: "3. Feature Analyses",
    meta: {layout : "survey-navbar", requireAuth: true},
    component: () =>
      import(/* webpackChunkName: "featureAnalysis" */ "../views/FeatureAnalyses.vue"),
  },
  {
    path: "/emotionalAnalysis",
    name: "4. Emotional Linguistic Analysis",
    meta: {layout : "survey-navbar", requireAuth: true},
    component: () =>
      import(/* webpackChunkName: "emotionalAnalysis" */ "../views/EmotionalAnalysis.vue"),
  },
  { path: '/:pathMatch(.*)*', beforeEnter: (to, from, next) => { next('/404') } },
  {
    path: '/404',
    name: '404',
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/PageNotFound.vue"),
  },
]
});


router.beforeEach((to,from,next)=>{
  const requiresAuth= to.matched.some(record=>record.meta.requireAuth)
  const currentUser = store.state.loggedInUser;
  if(requiresAuth && !currentUser){
    next('/login');}
  else if((to.name=='Login' || to.name=='loginView') && currentUser){
     next(store.state.currentRoutePath)
  }
  else if((to.name=='surveysList') && currentUser && store.state.selectedSurvey){
    next(store.state.currentRoutePath)
  }
  else if((to.path=='/audienceDefinition' || to.path=='/screenerQuestions' || to.path=='/featureAnalyses' ||  to.path=='/emotionalAnalysis') && !store.state.selectedSurvey){
    next('/surveysList')
  }
  else { next()}
 })
 export default router;