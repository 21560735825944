/* eslint-disable prettier/prettier */
import Vue from "vue";
import Vuex from "vuex";
import authAxios from "../services/api.service"
import getApiService from "../services/get.api.service"
import router from "@/router";
import VuexPersistence from 'vuex-persist'
const CryptoJS = require("crypto-js")
Vue.use(Vuex);

const store ={
  state: {
    selectedSurvey:null,
    currentRoutePath : null,
    studyName: "",
    subjectName: "",
    loggedInUser:null,
    surveysList:[],
    rememberMeCheckbox:false,
    questions: [
    {
    questionText: "",
    allSelectedFlag:true,
    selectedDeliverables:["White","Black","Hispanic","Asian"],
    answers: [{answerText : "",checkboxLabel:""},{answerText : "",checkboxLabel:""}]},
    ],
    featureAnalyses: [
      {featureType: "",
      allSelectedFlag:true,
      selectedDeliverables:["White","Black","Hispanic","Asian"],
      featureLeadIn:"",
      featureBehaviour:"",
      featuresList: [{featureName : ""}]},
    ],
    categories : [{
      categoryType: "",
      allSelectedFlag:true,
      selectedDeliverables:["White","Black","Hispanic","Asian"],
      elements:[{elementLanguage:""}]}
    ],
    featureSubNavArray:[],
    deliverDropdownList : ["White","Black","Hispanic","Asian"],

  },
  getters: {
  getRememberMeCheckboxValue:  state => {
    return state.rememberMeCheckbox
  },
  getSelectedSurvey:  state => {
    return state.selectedSurvey
  },
  getLoggeInUser:  state => {
    return state.loggedInUser
  },
  getSurveysList:  state => {
    return state.surveysList
  },
  getStudyName:  state => {
    return state.studyName
  },
  getSubjectName:  state => {
    return state.subjectName
  },
  getShowFeatureSubNavFlag:  state => {
    return state.featureSubNavArray
  },
  getScreenerQuestions:  state => {
    return state.questions
  },
  getFeatureAnaylses:  state => {
    return state.featureAnalyses
  },
  getCategories:  state => {
    return state.categories
  },
  getDeliverToDropdownList:  state => {
    return state.deliverDropdownList
  },
  },
  mutations: {
  /*Set Remember_Me Checkbox Value */
  setRememberMeCheckbox(state,payload){
  state.rememberMeCheckbox = payload
  },
  /*Set LoggedIn User Data */
  setLoggedInUser(state,payload){
    state.loggedInUser = payload;
  },
  /*Set Current Route Path */
  setCurrentRoute(state,payload){
    state.currentRoutePath = payload.path;
  },
  /*Set Surverys List */
  setSurveysList(state,payload){
    state.surveysList = payload;
  },
   /*Remove Selected Survery*/
  removeSelectedSurvey(state,payload){
    state.surveysList = state.surveysList.filter((item)=>{
    return item._id != payload.id;
    });

  },
  /*Set selected Survey */
  setSelectedSurvey(state,payload){
    state.selectedSurvey = payload;
  },
  /*Audienece Defination Step1 Update Fields */

  /*Set Target Data*/
  setTargetData(state,payload){
  state.studyName= payload.studyName;
  state.subjectName = payload.populationName;
  },

  /*Update Population Name Input*/
  updateSubjectNameInput(state,payload){
    state.subjectName = payload;
  },
  /*Update Study Name Input*/
  updateStudyNameInput(state,payload){
    state.studyName = payload;
  },
  /* Screener Question Step 2 CRUD Operation */

  /*Set Screener Questions Data*/
  setScreenerQuestions(state,payload){
  payload && payload.length ? state.questions = payload  : ()=>{}
  },
  /*Add New Screener Question*/
  addNewScreenerQuestion(state,payload){
    state.questions.push(payload);
  },
  /*Add New Answer in Selected Question*/
  addNewAnswer(state,payload){
    let newAnswer = {
      answerText : "",
      checkboxLabel:""
    }
    state.questions[payload].answers.push(newAnswer);
  },
  /*Delete Selected Question*/
  deleteSelectedQuestion(state,payload){
    state.questions.splice(payload, 1);
  },
  /*Delete Selected Answer*/
  deleteSelectedAnswer(state,payload){
    state.questions[payload.questionIndex].answers.splice(payload.answerIndex,1);
  },
  /*Update Question Input*/
  updateQuestionInput(state,payload){
    state.questions[payload.selectedIndex].questionText = payload.inputValue;
  },
   /*Update Answer Input*/
  updateAnswerInput(state,payload){
    state.questions[payload.selectedQuestion].answers[payload.selectedIndex].answerText = payload.inputValue;
  },
  /*Update Answer Inc/Exc Value*/
  selectedAnswerIncOrExc(state,payload){
    state.questions[payload.selectedQuestion].answers[payload.selectedIndex].checkboxLabel = payload.inputValue;
  },

  /* Feature Analyses Step 3 CRUD Operation */

  /*Set Feature Analysis Data*/
  setFeatureAnalysesData(state,payload){
    if(payload && payload.length){
        state.featureAnalyses = payload;
        for(let index in payload){
          state.featureSubNavArray.push(false);
        }
    }
    else {
      state.featureSubNavArray.push(false);
    }
  },
  /*Add New Analysis*/
  addNewFeatureAnalysis(state,payload){
    state.featureAnalyses.push(payload);
    state.featureSubNavArray.push(false);
  },
  /*Add New Feature in Analysis*/
  addNewFeature(state,payload){
    let newFeature = {
      featureName : ""
    }
    state.featureAnalyses[payload].featuresList.push(newFeature);
  },
  /*Delete Selected Feature Analysis*/
  deleteSelectedAnalysis(state,payload){
    state.featureAnalyses.splice(payload,1);
    state.featureSubNavArray.splice(payload, 1);
  },
  /*Delete Feature of Selected Analysis*/
  deleteSelectedFeature(state,payload){
    state.featureAnalyses[payload.analysisIndex].featuresList.splice(payload.featureIndex,1);
  },
  /*Update Feature Analysis Type Input*/
  updateAnalysisType(state,payload){
    state.featureAnalyses[payload.selectedIndex].featureType = payload.inputValue;
  },
  /*Update Feature Analysis Behaviour Input*/
  updateAnalysisLeadIn(state,payload){
    state.featureAnalyses[payload.selectedIndex].featureLeadIn = payload.inputValue;
  },
  /*Update Feature Analysis Behaviour Input*/
  updateAnalysisBehaviour(state,payload){
    state.featureAnalyses[payload.selectedIndex].featureBehaviour = payload.inputValue;
  },
  /*Update Feature Name Input*/
  updateFeatureNameInput(state,payload){
    state.featureAnalyses[payload.selectedAnalysis].featuresList[payload.selectedFeature].featureName = payload.inputValue;
  },
  /*Update Hide/Show subNavBar of Feature Analysis */
  updateShowFeatureSubNavArray(state,payload){
    state.featureSubNavArray[payload] = !state.featureSubNavArray[payload]
  },
  /* Emotional Analysis Step  4 CRUD Operation */
  setEmotionalAnalysisData(state,payload){
    if(payload && payload.length){
      state.categories = payload;
    }
  },
  /*Add new Emotional Analysis */
  addNewCategory(state,payload){
    state.categories.push(payload);
  },
  /*Add new Element in Emotional Analysis */
  addNewElement(state,payload){
    let newElement={
      elementLanguage:""
      }
    state.categories[payload].elements.push(newElement);
  },
  /*Delete Selected Emotional Analysis */
  deleteSelectedCategory(state,payload){
    state.categories.splice(payload,1);
  },
  /*Delete Element in Selected Emotional Analysis */
  deleteSelectedElement(state,payload){
    state.categories[payload.categoryIndex].elements.splice(payload.elementIndex,1);
  },
  /*Update Emotional Analysis Type Input*/
  updateCategoryTypeInput(state,payload){
    state.categories[payload.selectedIndex].categoryType = payload.inputValue;
  },
  /*Update Element Language Input */
  updateElementLanguageInput(state,payload){
    state.categories[payload.selectedCategory].elements[payload.selectedElement].elementLanguage = payload.inputValue;
  },
  /* Select All Deliverable */
  selectAllDeliverables(state,payload){
  let selectedQuestion =  payload.selectedStepData[payload.selectedQuestionIndex]
  selectedQuestion.allSelectedFlag ? selectedQuestion.allSelectedFlag = false : selectedQuestion.allSelectedFlag = true;
  selectedQuestion.allSelectedFlag ? selectedQuestion.selectedDeliverables = ["White","Black","Hispanic","Asian"] : ()=>{}
  },
  /* Update Deliverables Value */
  addOrRemoveDeliverables(state,payload){
  let selectedQuestionDeliverables =  payload.selectedStepData[payload.selectedIndex].selectedDeliverables;
  if(selectedQuestionDeliverables.includes(payload.selectedItem)){
    payload.selectedStepData[payload.selectedIndex].selectedDeliverables = selectedQuestionDeliverables.filter((value)=>{
    return value != payload.selectedItem
  })
  }
  else{
    payload.selectedStepData[payload.selectedIndex].selectedDeliverables.push(payload.selectedItem);
  }
  },
  /* Reset All Survey Data */
  resetSurveyData(state){
  state.selectedSurvey=null;
  state.rememberMeCheckbox=false;
  state.studyName= "";
  state.subjectName= "";
  state.questions = [
  {
  questionText: "",
  allSelectedFlag:true,
  selectedDeliverables:["White","Black","Hispanic","Asian"],
  answers: [{answerText : "",checkboxLabel:""},{answerText : "",checkboxLabel:""}]},
  ];
  state.featureAnalyses = [
    {featureType: "",
    allSelectedFlag:true,
    selectedDeliverables:["White","Black","Hispanic","Asian"],
    featureLeadIn:"",
    featureBehaviour:"",
    featuresList: [{featureName : ""}]},
  ];
  state.categories = [{
    categoryType: "",
    allSelectedFlag:true,
    selectedDeliverables:["White","Black","Hispanic","Asian"],
    elements:[{elementLanguage:""}]}
  ];
  state.featureSubNavArray=[];
  }
  },

actions: {
/* Update Selected Survey */
submitSelectedSurvey(context){
  return new Promise((resolve, reject) => {
    let data = {
      surveyInfo : {
      id : context.getters.getSelectedSurvey._id,
      name:context.getters.getSelectedSurvey.name
      },
      target : {
      populationName : context.getters.getSubjectName,
      studyName : context.getters.getStudyName,
      segments : ["White","Black","Hispanic","Asian"],
      },
      questions : {
      screenerQuestions : context.getters.getScreenerQuestions,
      featureAnalyses : context.getters.getFeatureAnaylses,
      emotionalLingusticAnalysis : context.getters.getCategories
      }
    }
    authAxios
    .post("survey/update", JSON.parse(localStorage.getItem("token")), data)
    .then((response) => {
      context.commit('resetSurveyData');
      router.push("/surveysList");
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
},
/* Signin User */
userLogin(context, data) {
  return new Promise((resolve, reject) => {
    authAxios
    .post("user/signin", null, data)
    .then((response) => {
      if(context.state.rememberMeCheckbox){
      localStorage.setItem("remember_me_chkbox",context.state.rememberMeCheckbox);
      data.password = CryptoJS.AES.encrypt(data.password,"My Secret Passphrase").toString();
      localStorage.setItem("user",JSON.stringify(data));
      }
      else{
        localStorage.removeItem("remember_me_chkbox");
        localStorage.removeItem("user");
      }
      let loggedInUserData = {
      userName : response.data.user?.firstname +' ' + response.data.user?.lastname,
      email : response.data.user.email
      }
       context.commit("setLoggedInUser", loggedInUserData);
       localStorage.setItem("token",JSON.stringify(response.data.user.session_token));
        resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
},
/* get LoggedInUser Surveys List */
fetchSurveyList(context){
  return new Promise((resolve, reject) => {
    getApiService
    .get("survey",JSON.parse(localStorage.getItem("token")))
      .then((response) => {
        context.commit("setSurveysList",response.data.surveys)
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
},
/* Delete Selected Survey */
deleteSurvey(context,data){
  return new Promise((resolve, reject) => {
    authAxios
    .post("survey/delete", JSON.parse(localStorage.getItem("token")), data)
    .then((response) => {
      context.commit("removeSelectedSurvey",data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
},
/* get Selected Survey  Data */
getSelectedSurveyData(context,data){
  return new Promise((resolve, reject) => {
  getApiService
    .get(`survey/${data}`,JSON.parse(localStorage.getItem("token")))
    .then((response) => {
      let selectedSurveyData = response.data.survey[0];
      context.commit('setTargetData',selectedSurveyData.target);
      context.commit('setScreenerQuestions',selectedSurveyData.questions.screenerQuestions);
      context.commit('setFeatureAnalysesData',selectedSurveyData.questions.featureAnalyses);
      context.commit('setEmotionalAnalysisData',selectedSurveyData.questions.emotionalLingusticAnalysis);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
},

};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
store.plugins=[vuexLocal.plugin]

export default new Vuex.Store(store)