const axios = require("axios").default;

//BASE URL For backend
import { baseURL } from "@/assets/js/urls";
const baseUrl = baseURL.API_URL;

const ApiService = {
  post: (endpoint, token, body) => {
    let customAxios = "";
    if (!token) {
    customAxios = axios.create({
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    else {
    customAxios = axios.create({
        headers: {
        'Content-Type': 'application/json',
        Authorization: `token ${token}`,
        },
    });
    }
    return customAxios.post(`${baseUrl}${endpoint}`, body);
  },
};

export default ApiService;