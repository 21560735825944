<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
const default_layout = "no-navbar";
export default {
  computed: {
    layout() {
      console.log((this.$route.meta.layout || default_layout) + "-layout");
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
  watch: {
    $route: function (newValue) {
      console.log("newValue: ", newValue);
      this.$store.commit("setCurrentRoute", newValue);
    },
  },
};
</script>
