<template>
  <div id="bt-topnav" class="navbar sticky-top  navbar-light bg-light text-dark row gx-1">
    <!-- Top Sticky Nav Element -->
      <div class="col-sm-1">
        <a class="navbar-brand text-light pointer" @click="unSelectSurvey()">
          <img src="@/assets/brand/bt-logo.svg" alt="" width="50" height="50" class="d-inline-block align-text-top">
        </a>
      </div>
      <div class="col-sm-11 d-flex">
        <h2 v-if="$route.path == '/surveysList'">Welcome back, {{getLoggeInUser ? getLoggeInUser.userName : 'Username'}}.</h2>
        <h2 v-else>Step {{$route.name}}</h2>
        <button type="button" class="ml-auto mt-1 mr-3 btn btn-primary"
        @click="logoutUser()"
        data-toggle="tooltip" data-placement="bottom" title="Logout">
        <i class="fa fa-sign-out" style="font-size:25px"></i>
      </button>
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
computed: {
  ...mapGetters([
    'getLoggeInUser',
  ]),
},
methods:{
  logoutUser(){
  localStorage.removeItem("token");
  this.$store.commit('setLoggedInUser',null);
  this.$store.commit('setSurveysList',null);
  this.$store.commit('resetSurveyData');
  this.$router.push('/login');
  },
  unSelectSurvey(){
  this.$store.commit('resetSurveyData');
  this.$router.push("/surveysList");
  }
}
}

</script>

<style scoped>
#bt-topnav{
  width: 100%;
}

</style>